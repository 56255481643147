import React, { useRef, useState } from "react";
import Icon from "../../component/Icon/Icon";
import { posts as postsApi } from "../../api/posts";
import { useDispatch, useSelector } from "react-redux";
import ShareModal from "../../component/ShareModal/ShareModal";
import Button from "../../component/Button/Button";
import styles from "./ComponentCss/newsfeedcard.module.css";
import BuyModal from "../../component/BuyModal/BuyModal";
import { useEffect } from "react";
import ReportButton from "../../component/ReportButton/ReportButton";

const PostReaction = (props) => {
    const user = useSelector((store) => store.user.user);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [post, setPost] = useState(props.post);
    const dispatch = useDispatch();
    // Like post code and variables
    const likeBtn = useRef();
    const [showShareModal, setShowShareModal] = useState(false);

    const onLike = async (e) => {
        e.preventDefault();
        likeBtn.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                param_path: `${post.id}/like`,
            };
            const response = await dispatch(postsApi(params));

            if (response.success) {
                props.updatePost(response.data);
            }
        } catch (err) {
            console.log(err);
        }
        likeBtn.current?.setLoader(false);
    };

    return (
        <>
            <ShareModal
                post={post}
                show={showShareModal}
                handleClose={() => setShowShareModal(false)}
            />
            <div
                className={`${styles["stat-tools"]} stat-tools d-flex flex-wrap m-0`}
            >
                <div className={`mr-1 mt-1`}>
                    {post.type != "challenge" && post?.uri && (
                        <Button
                            className={`btn-sm mr-1 rounded-pill btn-light`}
                            type="button"
                            onClick={onLike}
                        >
                            <Icon
                                name="like"
                                className={
                                    props.post.is_liked ? "text-info" : ""
                                }
                            />{" "}
                            Like
                        </Button>
                    )}

                    {post.type == "challenge" &&
                        post?.challenge_status == "voting" &&
                        post?.uri && (
                            <Button
                                className={`btn-sm mr-1 rounded-pill btn-light`}
                                type="button"
                                onClick={onLike}
                            >
                                <Icon
                                    name="stamp"
                                    className={
                                        props.post.has_voted ? "text-info" : ""
                                    }
                                />{" "}
                                Vote
                            </Button>
                        )}

                    {post?.uri && (
                        <Button
                            className={`btn-sm mr-1 rounded-pill btn-light`}
                            type="button"
                            onClick={() => props.toggleComments()}
                        >
                            <Icon name="comment" /> Comment
                        </Button>
                    )}

                    {/* <Button
                        className={`btn-sm mr-1 rounded-pill btn-light`}
                        type="button"
                        onClick={() => setShowShareModal(true)}
                    >
                        <Icon name="share" /> Share
                    </Button> */}

                    {!post?.uri && (
                        <Button
                            className={`btn-sm mr-1 rounded-pill btn-light`}
                            onClick={() => {
                                setShowBuyModal(true);
                            }}
                            type="button"
                        >
                            <Icon name="dollar" /> Buy
                        </Button>
                    )}

                    {showBuyModal && (
                        <BuyModal
                            show={showBuyModal}
                            post={post}
                            update={(p) => {
                                setPost(p);
                                setShowBuyModal(false);
                                props.updatePost(p);
                            }}
                            hide={() => {
                                setShowBuyModal(false);
                            }}
                        />
                    )}

                    {post.created_by != user.id && (
                        <ReportButton postId={post.id} />
                    )}

                    {/* <div className={`Like`}>
                        {post.type != "challenge" && <Icon wrapperclassName={`Like__link`} title="Like" name="like" className={props.post.is_liked ? "text-info" : ""} onClick={onLike} />}
                        {post.type == "challenge" && post?.challenge_status == "voting" && (
                            <Icon wrapperclassName={`Like__link`} title="Vote" name="like" className={props.post.has_voted ? "text-info" : ""} onClick={onLike} />
                        )}
                    </div> */}
                </div>
                {/* <div className={`box mr-1 mt-1`}>
                    <Icon onClick={() => props.toggleComments()} wrapperclassName={`comment-to`} title="Comment" name="comment" />
                </div>
                <div className={`box mr-1 mt-1`}>
                    <Icon wrapperclassName={``} title="Share" name="share" onClick={() => setShowShareModal(true)} />
                </div> */}
            </div>
        </>
    );
};

export default PostReaction;
