import React, { useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ThemeLayout from "layout/themeLayout";
import { useDispatch } from "react-redux";
import { posts } from "api/posts";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import Button from "component/Button/Button";
import Video from "component/Video/Video";
import { PLACEHOLDER, DATE_FORMAT } from "helpers/constant";
import moment from "moment";
import { comments } from "../../../api/comment";
import { useSelector } from "react-redux";
import { setPostDetail } from "../../../redux/actions/post";
import PostStats from "../../../Widgets/Posts/PostStats";
import PostReaction from "../../../Widgets/Posts/PostReaction";
import PostComments from "../../../Widgets/Posts/PostComments";
import { toast } from "react-toastify";
import ShareModal from "../../../component/ShareModal/ShareModal";
import BackButton from "../../../component/BackButton/BackButton";
import RoundImage from "../../../component/Image/RoundImage";
import ConfirmModal from "../../../component/ConfirmModal/ConfirmModal";
import { response } from "express";
import { fromNow } from "../../../helpers/dates";
import UnSafePostModal from "../../../component/UnsafePostModal/UnsafePostModal";
import Icon from "../../../component/Icon/Icon";
import ClubPostModal from "../../../component/ClubPostModal/ClubPostModal";

const PostDetail = React.memo(() => {
    const history = useHistory();

    const [commentText, setCommentText] = useState("");
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const { post_id } = useParams();
    const [showComments, setShowComments] = useState(true);
    const likeBtn = useRef();

    const postDetail = useSelector((store) => store.post.postDetail || {});
    const user = useSelector((store) => store.user.user);
    const [postActions, setPostActions] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shared, setShared] = useState(false);
    const [source, setSource] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [showUnSafePostModal, setShowUnSafePostModal] = useState(false);
    const [showClubPostModal, setShowClubPostModal] = useState(false);

    const deleteAction = {
        title: "Delete Post",
        subText: "If inappropriate Post By Mistake",
        icon: "ui-delete",
        action: () => {
            setShowConfirm(true);
        },
    };
    const editAction = {
        title: "Edit Post",
        subText: "Edit Post",
        icon: "ui-edit",
        action: () => {
            if (postDetail?.type == "club") {
                setShowClubPostModal(true);
            }

            // if (postDetail?.type == "challenge") {
            // }

            if (postDetail?.type == "user") {
                history.push(`/post/${postDetail.id}/edit`);
            }
        },
    };
    const deletePost = async () => {
        likeBtn.current?.setLoader(true);

        try {
            const params = {
                param_path: post_id,
                method: "DELETE",
            };

            const response = await dispatch(posts(params));
            if (response.success) {
                setShowConfirm(false);
                toast.success(response.message);
                history.replace("/profile");
            }
        } catch (err) {
            console.log(err);
        }

        likeBtn.current?.setLoader(false);
    };

    const getPostDetails = async () => {
        setLoader(true);
        try {
            const params = {
                param_path: post_id,
            };
            const response = await dispatch(posts(params));
            if (response.success) {
                console.log(response.data)
                dispatch(setPostDetail(response.data));
            }
        } catch (e) {
            if (e.response.status == 404) {
                history.replace("/notfound");
            } else {
                console.log("e", e);
            }
        }
        setLoader(false);
    };


    useEffect(() => {
        if (user.id && postDetail.created_by) {
            let urlParams = new URLSearchParams(window.location.search);
            let source = urlParams.get("source");
            if (source) {
                setSource(source);
            }

            if (urlParams.get("comment") == "true") {
                setShowComments(true);
            }

            if (user.id === postDetail.created_by.id) {
                console.log("user id: ", user?.id, ", creator id: ", postDetail?.created_by?.id);
                setPostActions((p) => [...p, editAction]);
                setPostActions((p) => [...p, deleteAction]);
            } else {
                if (postDetail.is_public == 0) {
                    history.replace(`/notfound`);
                }
            }

        }
    }, [postDetail, user]);

    const onComment = async (e) => {
        e.preventDefault();
        try {
            const params = {
                method: "POST",
                params: {
                    post_id,
                    comment: commentText,
                },
            };
            await dispatch(comments(params));
            getPostDetails();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (postDetail.id != post_id) {
            getPostDetails();
        }
        return () => {
            dispatch(setPostDetail({})); // clearing the global space
        };
    }, [post_id, dispatch]);

    const toggleComments = () => {
        setShowComments((p) => !p);
    };

    const updatePost = (data) => {
        dispatch(setPostDetail(data));
    };

    console.log(postDetail)
    return (
        <ThemeLayout>
            <ConfirmModal showModal={showConfirm} title="Confirm" message={`Are you sure you want to delete this post?`} onConfirm={() => deletePost(postDetail.id)} onClose={() => setShowConfirm(false)} />

            <div className="container mt-2">

                <div className="text-left">
                    <BackButton />
                </div>
                <ShareModal post={postDetail} show={showShareModal} handleClose={() => setShowShareModal(false)} source={source} />
                <UnSafePostModal post={postDetail} showModal={showUnSafePostModal} onHide={() => setShowUnSafePostModal(false)} onClose={() => setShowUnSafePostModal(false)} backdrop="static" />
                <ClubPostModal post={postDetail} showModal={showClubPostModal} handleClose={() => setShowClubPostModal(false)} updatePost={updatePost} />

                <div className="row justify-content-md-center">
                    <div className="col-lg-8">
                        <div className="main-wraper">
                            <div className="user-post">
                                <div className="friend-info ">
                                    <div className="d-flex align-items-center">
                                        <RoundImage className={`mr-2`} src={postDetail?.created_by?.profile_pic || PLACEHOLDER} size="40px" />
                                        <div className="flex-fill">
                                            <div className="">
                                                <ins>
                                                    <Link to={`/profile/${postDetail?.created_by?.id}`}>{postDetail?.created_by?.name}</Link>
                                                </ins>
                                                    {postDetail?.mentions?.length > 0 && (
                                                        <span className="ml-1">
                                                            is with{" "}
                                                            <small className="mr-1">
                                                                <Link className={`text-capitalize text-info`} to={`/profile/${postDetail?.mentions[0]?.id}`}>
                                                                    {postDetail?.mentions[0]?.name}
                                                                </Link>
                                                            </small>
                                                            {postDetail?.mentions?.length > 1 && 
                                                                <>
                                                                    <small>&</small>{" "}
                                                                    <small id={postDetail?.id} className={`cursor-pointer`}>
                                                                        {postDetail?.mentions.length - 1} other(s)
                                                                    </small>
                                                                    <ReactTooltip anchorId={postDetail.id} place="right" variant="light" className="border" float={true} clickable={true}>
                                                                        {postDetail?.mentions?.slice(1).map((tu, i) => {
                                                                            return (
                                                                                <small key={i} className="tag-user ml-1 d-block">
                                                                                    <Link className={`text-capitalize text-info`} to={`/profile/${tu.id}`}>
                                                                                        {tu.name}
                                                                                    </Link>
                                                                                </small>
                                                                            );
                                                                        })}
                                                                    </ReactTooltip>
                                                                </>
                                                            }
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="">
                                                <span>
                                                    <i className={`icofont-${postDetail?.is_public == 1 ? "globe" : "lock"} `} /> published: {fromNow(postDetail?.created_at)}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="actions mr-2">
                                            <Button type={`button`} className={`btn-sm btn`} onClick={() => history.replace(`/post/${postDetail.id}/edit`)}>Edit Post</Button>
                                        </div> */}
                                        {postActions.length > 0 && (
                                            <div className="more">
                                                <div className="more-post-optns">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-more-horizontal"
                                                    >
                                                        <circle cx={12} cy={12} r={1} />
                                                        <circle cx={19} cy={12} r={1} />
                                                        <circle cx={5} cy={12} r={1} />
                                                    </svg>
                                                    <ul>
                                                        {postActions.map((a, index) => {
                                                            return (
                                                                <li key={`${a.icon}-${index}`}>
                                                                    <Button
                                                                        onClick={() => a.action()}
                                                                        tType="link"
                                                                        style={{
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        <i className={`icofont-${a.icon}`}></i> {a.title}
                                                                        <span>{a.subText}</span>
                                                                    </Button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="post-meta">
                                        <p>{postDetail?.content}</p>
                                        {postDetail?.uri && (
                                            <div className="position-relative d-flex flex-column justify-content-center">
                                                <Video src={postDetail?.uri} style={{ height: "400px" }} postId={postDetail?.id} source={source} is_boosted={postDetail.is_boosted} />

                                                <div className="position-absolute" style={{ top: "15px", left: "10px" }}>
                                                    {!postDetail?.uri && <span className="badge badge-warning float-left mr-2">Premium</span>}
                                                    {postDetail?.status == "unsafe" && (
                                                        <span className="badge badge-danger float-left cursor-pointer" onClick={() => setShowUnSafePostModal(true)}>
                                                            <Icon name={`info-circle`} className={`mr-1`} />
                                                            Unsafe
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {postDetail?.uri && <PostStats post={postDetail} />}
                                        {postDetail?.uri && <PostReaction post={postDetail} toggleComments={toggleComments} updatePost={updatePost} />}
                                    </div>
                                </div>
                                {postDetail?.comments && <PostComments comments={postDetail?.comments} post_id={postDetail?.id} show={showComments} updatePost={updatePost} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeLayout>
    );
});

export default PostDetail;
