import React from "react";
import { useHistory } from "react-router-dom";

const PostStats = (props) => {
    const post = props.post;
    const history = useHistory();

    const showLikes = () => {
        if (post.no_likes > 0) {
            history.push(`/all/likes/${post.id}`);
        }
    };

    const showViews = () => {
        if (post?.no_views > 0) {
            history.push(`/all/views/${post.id}`);
        }
    };

    const showComments = () => {
        if (post?.no_comments > 0) {
            history.push(`/post/${post.id}?comment=true`);
        }
    };

    return (
        <ul className="p-0 pt-1 mb-0 text-muted" style={{ fontSize: "18px" }}>
            {post.type != "challenge" && (
                <li className="mr-2 d-inline" onClick={showLikes}>
                    <i className="icofont-like mr-1" style={{ fontSize: "14px" }}></i>{post.no_likes}
                </li>
            )}
            <li className="mr-2 d-inline" onClick={showViews}>
                <i className="icofont-eye-alt mr-1" style={{ fontSize: "14px" }}></i>{post.no_views}
            </li>
            <li className="mr-2 d-inline" onClick={showComments}>
                <i className="icofont-comment mr-1" style={{ fontSize: "14px" }}></i>{post.no_comments}
            </li>
            {/* <li className="mr-2 d-inline">
                <i className="icofont-share mr-1" style={{ fontSize: "14px" }}></i>{post.no_shares}
            </li> */}
            {post.type == "challenge" && (
                <li className="mr-2 d-inline">
                    <i className="icofont-stamp mr-1" style={{ fontSize: "14px" }}></i>{post.no_votes}
                </li>
            )}
        </ul>
    );
};

export default PostStats;
